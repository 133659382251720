<template >
  <v-card class="rtl" style="margin: 15px">
    <v-row style="margin: 2rem">
      <label for="buyPrice" class="col-4">وضعیت ورود دو مرحله ای</label>
      <v-btn v-if="LoginStatus === false" color="success" @click="EnableLoginByOtp()"> فعال شود</v-btn>
      <v-btn v-if="LoginStatus === true" color="error" @click="DisableLoginByOtp()">
        غیر فعال شود
      </v-btn>
    </v-row>

    <v-row style="margin: 2rem">
      <label class=" col-4">وضعیت ترید:</label>
      <v-btn v-if="tradeAllow === false" color="success" @click="TradeAllow()"> فعال شود</v-btn>
      <v-btn v-if="tradeAllow === true" color="error" @click="TradeDissallow()"> غیر فعال شود</v-btn>
    </v-row>
    <v-card flat>
      <v-card-title>
        اطلاعات ورود و خروج کاربر
        <v-spacer></v-spacer>
        <v-text-field
          single-line hide-details v-model="search" append-icon="mdi-magnify" label="جست و جو"
          class="searchStyle"
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="userLoginHistory" :search="search">
        <template v-slot:item.createdAtUtc="{ item }">
          <v-row>
            <span>{{getPersianDate(item.createdAtUtc)}}</span>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>
<script>
import axiosApi from "@/axios";
import Vue from "vue";
import getPersianDateTime from "@/plugins/getPersianDate";
export default {
  name: "SecurityUser",
  data(){
    return{
      LoginStatus : '',
      userLoginHistory : [],
      tradeAllow : '',
      search : '',
      headers: [
        {
          text: 'شناسه کاربری',
          align: 'start',
          sortable: false,
          value: 'username',
        },
        {text: 'تاریخ', value: 'createdAtUtc'},
        {text: 'آی پی', value: 'ipAddress'},
        {text: 'مرورگر', value: 'browserName'},
        {text: 'ورژن مرورگر', value: 'browserVersion'},
        {text: 'وضعیت', value: 'actionTitle'},
      ],
    }
  },
  mounted() {
      this.GetLoginByOtpStatus()
      this.getLoginHistory()
      this.getTradeAllow();
  },
  methods:{
    GetLoginByOtpStatus(){
      axiosApi().post('/api/Manage/v1/User/GetLoginByOtpStatus',{
        username :  this.$root.choosedUser
      })
        .then(({data}) => {
        if (data['isSuccess'])
          this.LoginStatus = data.data.isEnable
      })
    },
    EnableLoginByOtp(){
      axiosApi().post('/api/Manage/v1/User/EnableLoginByOtp',{
        username :  this.$root.choosedUser
      }).then(({data}) => {
        if (data['isSuccess'])
        {
          this.GetLoginByOtpStatus()
          Vue.$toast.open({
            message: 'ورود دو مرحله ای برای کاربر فعال شد',
            type: 'success',
            position:'top-right'
          })
        }
      })
    },
    DisableLoginByOtp(){
      axiosApi().post('/api/Manage/v1/User/DisableLoginByOtp',{
        username :  this.$root.choosedUser
      }).then(({data}) => {
        if (data['isSuccess'])
        {
          this.GetLoginByOtpStatus()
          Vue.$toast.open({
            message: 'ورود دو مرحله ای برای کاربر غیرفعال شد',
            type: 'success',
            position:'top-right'
          })
        }
      })
    },
    getLoginHistory() {
      axiosApi().post('/api/Manage/v1/Report/GetLoginsList',
        {
          username :  this.$root.choosedUser
        })
        .then(({data})=>{
          this.userLoginHistory = data.data.details
        })
    },
    getPersianDate(date){
      return getPersianDateTime(date);
    },
    TradeAllow() {
      axiosApi().post('/api/Manage/v1/User/TradeAllow',
        {
          username: this.$root.choosedUser
        })
        .then(({data})=>{
          if (data['isSuccess'] === true)
            this.getTradeAllow();
          Vue.$toast.open({
            message: 'وضعیت ترید کاربر با موفقیت تغییر یافت',
            type: 'success',
            position:'top-right'
          })
        })
    },
    TradeDissallow(){
      axiosApi().post('/api/Manage/v1/User/TradeDisallow',
        {
          username: this.$root.choosedUser
        })
        .then(({data})=>{
          if (data['isSuccess'] === true)
            this.getTradeAllow();
          Vue.$toast.open({
            message: 'وضعیت ترید کاربر با موفقیت تغییر یافت',
            type: 'success',
            position:'top-right'
          })
        })
    },
    getTradeAllow(){
      axiosApi().post('api/Manage/v1/User/GetList',
        {
          'username' : this.$root.choosedUser
        }).then(({data}) => {
        if (data['isSuccess'] == true)
          this.tradeAllow = data.data['0'].tradeAllowed
      },)
    },

  }
}
</script>

<style scoped>

</style>
